<template>
  <div>
    <div class="content-filters">
      <div class="content-filter-show-for">
        <v-skeleton-loader
          class="mx-auto"
          width="auto"
          type="text"
        ></v-skeleton-loader>
      </div>
      <div class="content-filter-date" @click="menu = true">
        <v-skeleton-loader
          class="mx-auto"
          width="auto"
          type="text"
        ></v-skeleton-loader>
      </div>
      <div class="content-filter-menu-btn">
        <v-skeleton-loader
          class="mx-auto"
          width="auto"
          type="text"
        ></v-skeleton-loader>
      </div>
    </div>
    <div class="content-line-chart">
      <p class="text-inventary">Inventario</p>
      <v-skeleton-loader
        height="350"
        class="ml-4"
        type="image"
      ></v-skeleton-loader>
      <p class="text-period">Periodos</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sShowFor: "Por periodo",
      items: ["Por periodo"],
      dates: [],
      menu: false,
      bEntries: false,
      bExits: false,
    };
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    dates() {
      if (this.dates.length >= 2) {
      }
    },
  },
};
</script>

<style scoped>


.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}
.content-filters {
  display: flex;
  width: 100%;
}
.content-filter-show-for {
  width: 25%;
  margin-right: 15px;
}
.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}
.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-line-chart {
  position: relative;
}
.text-inventary {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  left: -8px;
  bottom: 0;
  top: 20px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  letter-spacing: 14px;
}

.text-period {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  text-align: center;
  left: 0%;
  right: 0%;
  bottom: -15px;
  position: absolute;
  letter-spacing: 14px;
}

.content-filter-date {
  width: 25%;
}
.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }
  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 40%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 45%;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-filter-show-for {
    width: 25%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 30%;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>